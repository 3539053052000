@import "@design";

.shift {
  position: relative;
  flex: 1 0 auto;
  align-items: center;
  padding: 5px 5px;
  margin-bottom: 2px;
  overflow: hidden;
  color: $gray-dark;
  cursor: move;
  background-color: white;
  // border: 1px solid $gray-light;
  border-radius: 6px;
  opacity: 1;
  transition: background-color 0.3s ease, box-shadow 0.2s ease,
    opacity 0.4s ease;

  .remove-shift-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  &:hover {
    overflow: visible;
  }
  .sortable-ghost {
    width: 100%;
  }
  .job-number {
    font-weight: bold;
  }
  .small-data {
    font-size: 0.8rem;
  }
  &.new-shift {
    background-color: orange !important;
  }
  &.immutable {
    // padding-bottom: 20px;
    background-color: darken(white, 20%);
    &:not(.loc-hover) {
      opacity: 0.5;
      @media print {
        opacity: 1;
      }
    }
  }
  &.ghost {
    opacity: 0.3;
  }
  &.cannot-drag {
    cursor: pointer;
    user-select: none;
  }
  &.needs-setup {
    background: lighten($orange, 40);
  }

  .cal-cell-data {
    line-height: 1.1;
    margin: 0.45em 0;
    @media print {
      font-size: 0.7rem;
      margin: 0.25em 0;
      &.small-data {
        font-size: 0.6rem;
      }
    }
  }

  @media print {
    border: 1px solid hsl(0, 0, 50%) !important;
    box-shadow: none !important;
    &::before {
      display: none !important;
    }
  }
} // .shift

.report-popup {
  .v-card__title {
    .title,
    .subtitle {
      font-family: 'Avenir', sans-serif !important;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      margin-top: 0.3em;
    }
  }
}
